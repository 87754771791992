.Footer-container{
    position: relative;

}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;

}

.social-link{
    display: flex;
    gap: 4rem;
}

.social-link>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}
.blur-f-1{
    bottom: 0;
    right: 27%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background-color: red;

}

.blur-f-2{
    bottom: 0;
  left: 27%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: rgb(235, 115, 16);
}